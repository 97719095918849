.screenTable{
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    
}
#components-layout-demo-responsive .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
  
  .site-layout-sub-header-background {
    background: #fff;
  }
  
  .site-layout-background {
    background: #fff;
  }

  
.showClass{
  height: 80px;
  transition: all 1s linear;
  margin: 0px 40px;
  overflow: hidden;
}
.hideClass{
  height: 0;
  overflow: hidden;
  transition: all 1s linear;
  margin: 00px 40px
}
.searchIcon{
  transition: all .4s linear;
  font-size: 24px;
  color: #605363;
  padding: 4px;
  border-radius: 50%;
  /* color: #fff; */
  cursor: pointer;
  padding: 8px;
  border: 2px solid #fff;
  margin: 4px;
  margin-right: 1px;
}
.searchIcon:hover{
  transition: all .4s linear;
  border-radius: 50%;
  padding: 8px;
  color: #605363;
  border: 2px solid #605363;
}