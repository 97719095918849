.infoTable{
    border: 1px solid transparent;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
    margin-top: 80px;
    margin-bottom: 100px;
}
.topInfoTable {
    height: 90px;
    margin: auto;
    margin-top: -1.5rem;
    margin-left: 3rem;
    margin-right: 3rem;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    padding: 20px;
    margin-bottom: 1rem;
}
.topInfoTable h4{
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 700;
    color:#fff
}
.infoTable > .tableTitle {
    margin-top: 10px;
    position: absolute;
    margin-left: 9.4rem;
    font-size: 20px;
    font-weight: 400;
    color: #6f7884;
}