.login{
    background-color: #fff;
    
}
.loginContainer{
    max-width: 100%;
}
.loginLeft{
    /*border: 1px solid ;*/
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/Group\ 244.png');
    background-size: cover;
    filter:invert(3%) sepia(76%) saturate(80%) hue-rotate(312deg) brightness(120%) contrast(178%)
}
.loginRight{
    /*border: 1px solid ;*/
    width: 100%;
    min-height: 601px;
    max-height: 100%;
    background-color: #fff;
    padding: 50px 90px;
    
}
.noPadding{
    padding: 0;
}
.loginRight .btn{
    background-color: #605363 !important;
}
.login .forgetP{
    text-align: center;
    color: #605363;
    font-weight: 500;
    font-size: 18px
}
.logoDiv img {
    filter:opacity(0.5) drop-shadow(0 0 0 #000);
    margin-bottom: 50px !important;
}

