.screenTable{
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    
}
.MuiFab-extended.MuiFab-sizeMedium {
    width: auto;
    height: 35px !important;
    padding: 0 16px;
    min-width: 40px;
    border-radius: 4px !important;
}