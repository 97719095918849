
.infoCard{
    border: 1px solid transparent;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
    margin-top: 80px;
    margin-bottom: 40px;
}
.info h6{
    color: #999999 !important;
}
.infoCard > .btn.btn-block {
    margin: inherit;
    margin: 10px 15px !important;
    width: 92% !important;
    border-radius: 25px
}

.editCard{
    margin-top: 50px;
    margin-bottom: 50px;
    min-height: 400px;
    outline: none;
}
.editCard  .topIcon {
    color: #fff;
    padding-top: 28px;
    width: 80px;
    height: 80px;
    position: relative;
    margin-top: -6.6rem !important;
    margin-right: 4%;
    margin-left: 1.4rem;
    text-align: center;
    border-radius: 5px;
}
.editCard > .tableTitle {
    margin-top: -40px;
    position: absolute;
    margin-left: 8rem;
    font-size: 18px;
    font-weight: 400;
    color: #6f7884;
}