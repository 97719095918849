.needs-validation .md-form label {
    left: 0rem;
    top: -24px;
}
.removeImg3{
    top: 165px !important;
    position: absolute;
    right: 6%;
    color: red;
    font-size: 16px;
    cursor: pointer;
}