/********************************************upload////////////////////////*/
.fileUpload {
    background-color: #eeeeee;
    margin-top: 30px;
    height: 130px;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2), 1px 1px 2px 0px rgba(0,0,0,0.14), 0px -1px 3px 0px rgba(0,0,0,0.12);
}
.upImgs {
    opacity: 0;
    position: relative;
    height: 130px;
    width: 100%;
    z-index: 1000;
    cursor: pointer;
    float: left;
}
.camera{
    width: 130px;
    height: 90px;
    position: absolute;
    float: left;
    margin-left: -58%;
    margin-top: 1rem;
}
.btn.btn-block {
    margin: inherit;
    margin-bottom: 20px;
}
.preview{
    width: 100%;
    margin-top: 20px;
    float: left;
}
.preview img{
    width: 70px;
    height: 70px;
    margin: 10px;
}
.removeImg{
    position: relative;
    left: -25%;
    top: -18px;
    color: red;
    font-size: 18px;
}
/****************************slider**********************/

.productSlider {
    padding: 10px;
}

.image-gallery-slide img {
    width: 100%;
    height: 100%;
}
.image-gallery {
    height: 100% !important;
}
.image-gallery-content {
    height: 100% !important;
}
.image-gallery-slide-wrapper {
    position: relative;
    height: 80% !important;
}
.image-gallery-swipe{
    height: 100% !important;
}
.image-gallery-slides, .image-gallery-image {
  
    height: 100% !important;
}
.image-gallery-slide.center {
    position: relative !important;
    height: 100% !important;
}
.image-gallery-play-button{
    display: none;
}
.image-gallery-right-nav , .image-gallery-left-nav{
    background: transparent;
    font-size: 30px !important;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 25px !important;
}
.image-gallery-right-nav:focus , 
.image-gallery-left-nav:focus , 
.image-gallery-fullscreen-button:focus{
    background: transparent;
    outline: none;
}
.fullscreen .bottom{
    height: 100% !important;
}
.image-gallery-thumbnail img {
    vertical-align: middle;
    width: 100% !important;
    height: 60px !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(120vh - 80px);
    width: 100%;
}
.previewPdf{
    background-color: #eeeeee;
    height: 30px;
    padding:4px 6px 4px 15px;
    margin: 5px;
    border-radius: 18px;
    font-size: 14px;
    box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 20%), 1px 1px 2px 0px rgb(0 0 0 / 14%), 0px -1px 3px 0px rgb(0 0 0 / 12%);
    color: darkslateblue;
}
.removePdf{
    margin-left: 10px;
    color: red;
    cursor: pointer;
}
.grey-text{
    color:#333 !important
}

