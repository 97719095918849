.screenTable{
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    
}
.showClass160{
    height: 160px;
    transition: all 1s linear;
    margin: 0px 40px;
    overflow: hidden;
  }