
.infoCard{
    border: 1px solid transparent;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
    margin-top: 80px;
    margin-bottom: 40px;
}
.btnOption{
    border: 1px solid transparent;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
    margin-top: 0px;
    margin-bottom: 100px;
    width: 100%;
    padding: 30px;
}

.info h6{
    color: #999999 !important;
}
.infoCard > .btn.btn-block {
    margin: inherit;
    margin: 30px 15px !important;
    width: 92% !important;
    border-radius: 25px
}

.editCard{
    margin-top: 50px;
    margin-bottom: 50px;
    min-height: 500px;
    outline: none;
}
.editCard  .topIcon {
    color: #fff;
    padding-top: 28px;
    width: 80px;
    height: 80px;
    background: #605363;
    position: relative;
    margin-top: -6.6rem !important;
    margin-right: 4%;
    margin-left: 1.4rem;
    text-align: center;
    border-radius: 5px;
}
.tableTitle2 {
    margin-top: -20px;
    
    font-size: 18px;
    font-weight: 400;
    color: #6f7884;
    width: 100%;
    white-space: nowrap !important;
}
.myselect{
    margin-bottom: 16px;
    border: 0px;
    border-bottom: .5px solid #aaa;
    border-radius: 0px;
    outline: none;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #aaa;

}
.react-rater{
    float: left;
}
.react-rater-star.will-be-active , .react-rater-star.is-active {
    color: #ffb900 !important;
}
.react-rater > * {
    display: inline-block;
    font-size: 28px;
}
/********************************************upload////////////////////////*/
.fileUpload {
    background-color: #eeeeee;
    margin-top: 30px;
    height: 130px;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2), 1px 1px 2px 0px rgba(0,0,0,0.14), 0px -1px 3px 0px rgba(0,0,0,0.12);
}
.upImgs {
    opacity: 0;
    position: relative;
    height: 130px;
    width: 100%;
    z-index: 1000;
    cursor: pointer;
    float: left;
}
.camera{
    width: 130px;
    height: 90px;
    position: absolute;
    float: left;
    margin-left: -58%;
    margin-top: 1rem;
}
.btn.btn-block {
    margin: inherit;
    margin-bottom: 20px;
}
.preview{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    float: left;
}
.preview img{
    width: 70px;
    height: 70px;
    margin: 10px;
}

/****************************slider**********************/

.productSlider {
    padding: 10px;
}

.image-gallery-slide img {
    width: 100%;
    height: 100%;
}
.image-gallery {
    height: 100% !important;
}
.image-gallery-content {
    height: 100% !important;
}
.image-gallery-slide-wrapper {
    position: relative;
    height: 80% !important;
}
.image-gallery-swipe{
    height: 100% !important;
}
.image-gallery-slides, .image-gallery-image {
  
    height: 100% !important;
}
.image-gallery-slide.center {
    position: relative !important;
    height: 100% !important;
}
.image-gallery-play-button{
    display: none;
}
.image-gallery-right-nav , .image-gallery-left-nav{
    background: transparent;
    font-size: 30px !important;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 25px !important;
}
.image-gallery-right-nav:focus , 
.image-gallery-left-nav:focus , 
.image-gallery-fullscreen-button:focus{
    background: transparent;
    outline: none;
}
.fullscreen .bottom{
    height: 100% !important;
}
.image-gallery-thumbnail img {
    vertical-align: middle;
    width: 100% !important;
    height: 60px !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(120vh - 80px);
    width: 100%;
}




.md-form>label{
        position: absolute;
        top: -30px !important;
        left: -2px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 35px !important;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector{
    min-height: 35px !important;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
}
.ant-message{
    z-index: 9999;
}

.removeImg2{
    top: 30px;
    position: relative;
    right: 5%;
    float: right;
    color: red;
    font-size: 16px;
    cursor: pointer;
}
.imgName{
    font-size: 14px;
    font-weight: 400;
    color: black;
}
.previewImg2{
    width: 65px !important;
    height: 65px !important;
    margin: 7px !important;
    margin-bottom: 40px;
}


.fileUpload2{
    background-color: transparent;
    margin-top: 10px;
    height: 50px;
    box-shadow: none;
    border: 1px solid #dedede;
}
.upImgs2{
    height: 50px;
    opacity: 0;
    position: relative;
    width: 100%;
    z-index: 1000;
    cursor: pointer;
    float: left;
}
.uploadName{
    font-size: 13px;
    font-weight: 400;
    color: #000;
    font-family: auto;
}
.uploadParent{
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    margin-top: 9px;
}


